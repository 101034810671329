<template>
  <v-container fluid>
    <page-title title="Авторы" />
    <list-items :items="authorsList" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AuthorsList',
  metaInfo: {
    title: 'Авторы'
  },
  components: {
    ListItems: () => import('@/components/shared/lists/ListItems'),
    PageTitle: () => import('@/components/shared/PageTitle')
  },
  computed: {
    ...mapState({
      listAuthors: state => state.shared.listAuthors
    }),
    ...mapGetters(['filterListAuthors']),
    authorsList() {
      return this.filterListAuthors.map(item => ({
        ...item,
        path: `/author/${item.id}`,
        title: `${item.firstName} ${item.lastName}`
      }))
    }
  },
  created() {
    if (!this.listAuthors.length) this.$store.dispatch('getListAuthors')
  }
}
</script>

<style scoped></style>
